import React from "react"
import styled from "styled-components"
import { Container } from "./defaultComponents"

const Wrapper = styled(Container)`
  margin: 0 auto 5vw;
  padding-top: 5vw;

  & > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: brevia, sans-serif;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  & a {
    color: var(--text-color);
    text-decoration: none;
  }
`

// const LinkExtern = styled.a`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border: 2px solid white;
//     border-radius: 50%;
//     width: 50px;
//     height: 50px;
//     cursor: pointer;
//     color: white;
// `

const Impressum = () => {
  return (
    <Wrapper>
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Wiebke Klingsporn
        <br />
        Geb&auml;rdensprachdolmetscherin
        <br />
        Schwester-Firma-Weg 24
        <br />
        51143 K&ouml;ln
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: <a href="tel:+491781575480">0151 70803338</a>
        <br />
        E-Mail:{" "}
        <a href="mailto:wiebke.klingsporn@gmx.de">wiebke.klingsporn@gmx.de</a>
      </p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europ&auml;ische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          https://ec.europa.eu/consumers/odr/
        </a>
        .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </Wrapper>
  )
}

export default Impressum
