import * as React from "react"
import Impressum from "../components/Impressum"
import { GlobalStyle } from "../components/globalStyle"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Navbar from "../components/navbar2"

const impressum = () => {
  return (
    <>
      <Seo title="Impressum" />
      <Navbar />
      <GlobalStyle />
      <Impressum />
      <Footer />
    </>
  )
}

export default impressum
